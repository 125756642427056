import ComboBoxInputComponent from '@wix/thunderbolt-elements/src/components/ComboBoxInput/viewer/ComboBoxInput';
import ComboBoxInputController from '@wix/thunderbolt-elements/src/components/ComboBoxInput/viewer/ComboBoxInput.controller';


const ComboBoxInput = {
  component: ComboBoxInputComponent,
  controller: ComboBoxInputController
};


export const components = {
  ['ComboBoxInput']: ComboBoxInput
};

